<template>
  <kinesis-container>
    <div class="content">

      <!--      <KinesisElement strength="30">-->
      <img src="./../assets/image/gif2@1x.png" alt="" class="bg">
      <!--      </KinesisElement>-->

      <div class="mianContent">

        <div class="box1">


          <KinesisElement strength="5">
            <div class="headerImage">
              <img src="./../assets/image/header6@2x.png" alt="">
            </div>
          </KinesisElement>

          <KinesisElement strength="35">
            <div class="name">
              <div class="_name1">
                <img src="./../assets/image/ClickGenius@1x(1).png" alt="">
              </div>
              <div class="_name2">
                <img src="./../assets/image/ClickGenius@1x.png" alt="">
              </div>
              <div class="_name3">
                <img src="./../assets/image/ClickGenius@1x(2).png" alt="">
              </div>
            </div>


          </KinesisElement>
          <KinesisElement strength="20">
            <div class="dec">
              <span>Unlock the Power of TikTok-Shop with Ease</span>
            </div>
          </KinesisElement>

        </div>
        <div class="box2"></div>

        <div class="box3">
          <div class="box3Title">TikTok-Shop Operations Services</div>
          <KinesisElement strength="20">


            <div class="box3List"><img src="./../assets/icon/Vector@2x.png" alt="">Seamless Registration & Management
            </div>
            <div class="box3List"><img src="./../assets/icon/Vector@2x.png" alt="">Full Professional Operation
              Assistance
            </div>
            <div class="box3List"><img src="./../assets/icon/Vector@2x.png" alt="">Minimum $500/month Share Earning
            </div>
          </KinesisElement>


          <div class="contact" @click="turnBottom">
            CONTACT US
          </div>


        </div>
      </div>
    </div>
  </kinesis-container>
</template>

<script>
import {KinesisContainer, KinesisElement} from 'vue-kinesis'

export default {
  name: "home-views",
  components: {

    KinesisContainer,
    KinesisElement
  },
  methods: {
    turnBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  position: relative;
  height: 800px;

  .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 600px;
    object-fit: cover;
    z-index: -1;
  }

  .mianContent {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    width: 1000px;

    .box1 {
      background: white;
      position: relative;
      top: 100px;
      width: 400px;
      padding: 40px 20px 80px 20px;
      z-index: 9;

      .headerImage {
        img {
          width: 80px;
          position: relative;
          left: 90px;
        }
      }

      .name {
        position: relative;
        height: 80px;

        img {
          width: 350px;
        }

        ._name1 {
          position: absolute;
          left: 3px;
        }

        ._name2 {
          position: absolute;
          z-index: 3;
        }

        ._name3 {
          position: absolute;
          left: -3px;
          top: -3px;
        }
      }

      .dec {
        font-size: 24px;
        font-family: 'Oswald', sans-serif;
        color: #111111;
      }
    }

    .box2 {
      width: 250px;
      height: 650px;
      background: #FE2858;
      position: absolute;
      top: 40px;
      left: 400px;
      z-index: 8;
    }

    .box3 {
      background: #000000;
      color: white;
      font-size: 26px;
      font-family: 'Oswald', sans-serif;
      width: 500px;
      position: absolute;
      z-index: 9;
      padding: 10px 30px;
      top: 280px;
      right: -60px;
      font-weight: 400;
      padding-bottom: 30px;

      .box3List {
        line-height: 50px;

        img {
          width: 20px;
          height: 20px;
          padding-right: 10px;
        }
      }

      .box3Title {
        line-height: 70px;
      }

      .contact {
        margin-top: 30px;
        background: white;
        color: #000000;
        font-family: 'Montserrat', sans-serif;
        padding: 10px 20px;
        display: inline-block;
        font-weight: 600;
        cursor: pointer;
      }

    }
  }
}

@media screen and (max-width: 1180px) {
  .mainContent {
    width: 100%;
    background: black !important;
  }
}

</style>
