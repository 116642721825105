<template>
  <div>
    <header-views-learn></header-views-learn>
    <home-views-learn></home-views-learn>
    <footerViewsLearn></footerViewsLearn>
  </div>
</template>

<script>

import headerViewsLearn from "@/components/header-views-learn";
import homeViewsLearn from "@/components/home-views-learn";
import footerViewsLearn from "@/components/footer-views-learn";

export default {
  name: "all-learn-views",
  components: {
    headerViewsLearn,
    homeViewsLearn,
    footerViewsLearn
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>
