import {createRouter, createWebHashHistory} from 'vue-router';
import HomeView from "./../components/all-home-views";
import learnView from './../components/all-learn-views';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/learn',
        name: 'learn',
        component: learnView
    },
    // ... 其他路由
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes, // (缩写) 相当于 routes: routes
});

export default router;
