<template>
  <kinesis-container>
    <div class="content">
      <div class="_content">
        <div class="Bg"></div>
        <div class="swiper">
          <KinesisElement strength="35">
            <img src="./../assets/image/Rectangle27@0.75x.png" alt="">
          </KinesisElement>
          <br style="clear: both">

          <div class="swiperContent">
            <KinesisElement strength="5">
              <div class="title">Tax and Legal Support:</div>
              <div class="dec">Safeguarding Your Privacy</div>
            </KinesisElement>
          </div>

        </div>
        <div class="feature">

          <div class="featureLeft">
            <KinesisElement strength="15">
              <div class="title">
                Tax Compliance
              </div>
              <div class="dec">
                Professional tax team to ensure your e-commerce operations comply with tax requirements and provide tax
                solutions.
              </div>
            </KinesisElement>
          </div>
          <div class="featureRight">
            <KinesisElement strength="15">
              <div class="title">
                Legal Support
              </div>
              <div class="dec">
                legal team will provide expert support to ensure your e-commerce business operations comply with legal
                requirements and protect your interests.
              </div>
            </KinesisElement>
          </div>
        </div>
        <br style="clear: both">

      </div>

    </div>
  </kinesis-container>
</template>

<script>
import {KinesisContainer, KinesisElement} from "vue-kinesis";

export default {
  name: "legal-views",
  components: {

    KinesisContainer,
    KinesisElement
  }
}
</script>

<style scoped lang="less">
.content {
  height: 1000px;
  position: relative;
  background: #FFFFFF;

  ._content {
    width: 1000px;
    margin: auto;
    position: relative;
  }

  .Bg {
    width: 300px;
    background: #FE2858;
    height: 920px;
    position: absolute;
    z-index: 0;
    left: 100px;
  }

  .feature {
    width: calc(100% - 250px);
    float: right;
    margin-top: 120px;
    display: flex;

    .featureLeft, .featureRight {
      background: #F2F2F2;
      padding: 0px 30px 30px 30px;
      box-shadow: 8px 8px 0px #C4C4C4;
      z-index: 3;
      flex: 1;

      .title {
        color: #111111;
        text-align: center;
        font-size: 30px;
        line-height: 80px;
        font-weight: 400;
      }

      .dec {
        font-family: 'Montserrat', sans-serif;
        color: #000000;
        font-weight: 400;
      }
    }

    .featureRight {
      margin-left: 15px;
    }

    .featureLeft {
      margin-right: 15px;
    }
  }


  .swiper {
    width: 100%;
    padding-top: 100px;
    position: relative;

    img {
      width: calc(100% - 250px);
      float: right;
    }

    .swiperContent {
      position: absolute;
      top: 250px;
      width: 350px;
      padding: 30px;
      background: #000000;
      color: #FFFFFF;

      .title {
        font-size: 60px;
        font-weight: 400;
      }

      .dec {
        font-size: 26px;
        line-height: 100px;
      }
    }
  }
}

</style>
