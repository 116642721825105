<template>
  <div class="content">
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="_content">
      <div class="title">ClickGenius</div>
      <div class="dec">to Start your E-Commerce Journey with 0 experience</div>
      <div class="footer">
        <div class="email">support@aivocado.online</div>
        <div class="company">
          <div><img src="./../assets/icon/Group@1x.png" alt="" style="margin: 0px 10px">2024
            Privacy
            policy
          </div>
          <div class="_company">AIvocado, Inc.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-views-learn"
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 200px;
  display: flex;

  .bg1 {
    background: #33F3ED;
    flex: 1;
  }

  .bg2 {
    background: #FE2858;
    flex: 1;
  }

  ._content {
    background: black;
    flex: 3;
    text-align: center;

    .title {
      color: #65D2E9;
      font-size: 30px;
      line-height: 60px;
    }

    .dec {
      color: white;
    }

    .footer {
      display: flex;
      color: white;
      margin-top: 50px;

      .email {
        flex: 1;
      }

      .company {
        flex: 1;
        display: flex;
        border-top: 4px solid white;
        margin-right: 30px;
        text-align: center;


        ._company {
          margin-left: 30px;
          text-align: center;
        }
      }
    }
  }
}


</style>
