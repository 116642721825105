<template>
  <kinesis-container>
    <div class="content">
      <div class="title" style="display: none">
        <div class="bigTitle">Successful Collaborations</div>
        <div class="dec">Discover successful collaborations.
          <br>
          See how businesses like yours have thrived with our platform.
          <br>
          Join us and be the next success story!
        </div>
      </div>
      <div class="illustrate" style="display: none">
        <div class="mainContent">
          <div class="_illustrate">
            <div class="_illustrateImage">
              <img src="./../assets/image/gif2@1x.png" alt="">
            </div>
            <div class="_illustrateContent">
              Brand equity audit
              Audience analysis
              Competitive review
              Strategic direction
            </div>
          </div>
          <div class="_illustrate">
            <div class="_illustrateImage">
              <img src="./../assets/image/gif2@1x.png" alt="">
            </div>
            <div class="_illustrateContent">
              Brand equity audit
              Audience analysis
              Competitive review
              Strategic direction
            </div>
          </div>
          <div class="_illustrate">
            <div class="_illustrateImage">
              <img src="./../assets/image/gif2@1x.png" alt="">
            </div>
            <div class="_illustrateContent">
              Brand equity audit
              Audience analysis
              Competitive review
              Strategic direction
            </div>
          </div>
        </div>
      </div>
      <div class="title_2">

        <div class="bigTitle_2">Full Service Management</div>
      </div>
      <div class="mainContent2">
        <div class="_mainContent2">
          <div class="mainContent2Left">
            <KinesisElement strength="45">
              <div class="mainContent2LeftTitle">Operation Assistance</div>
              <div class="mainContent2LeftTitle">Company Management</div>
              <div class="mainContent2LeftTitle">Monthly Earnings Distrubition</div>
              <div class="mainContent2LeftTitle">Profit Maximization</div>
            </KinesisElement>
          </div>
          <div class="mainContent2LeftBg"></div>
          <div class="mainContent2Right">
            <KinesisElement strength="15">
              <div class="mainContent2RightTitle">Effortlessly register and manage your company</div>
              <div class="mainContent2RightTitle">Professional team handles operations, ensuring seamless support.</div>
              <div class="mainContent2RightTitle">Maximize profits and enjoy monthly dividends from platform earnings.
              </div>
            </KinesisElement>
            <div class="bgImage"><img src="./../assets/image/header6@2x.png" alt=""></div>
          </div>
        </div>

      </div>
    </div>
  </kinesis-container>
</template>

<script>
import {KinesisContainer, KinesisElement} from "vue-kinesis";

export default {
  name: "case-views",
  components: {

    KinesisContainer,
    KinesisElement
  }
}
</script>

<style scoped lang="less">
.content {

  .title {
    background: #FE2858;
    color: white;
    padding: 20px;


    .bigTitle {
      font-size: 60px;
      text-align: center;
      line-height: 120px;
    }

    .dec {
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      font-size: 20px;
      font-style: italic;
      padding-bottom: 40px;
      line-height: 30px;

    }
  }

  .illustrate {
    background: #040404;
    height: 600px;


    .mainContent {
      display: flex;
      height: 500px;
      width: 1000px;
      margin: auto;

      ._illustrate {
        background: white;
        flex: 1;
        margin: 50px 40px;

        ._illustrateContent {
          font-family: 'Roboto', sans-serif;
          padding: 30px 20px;
          color: #000000;
        }

        ._illustrateImage {
          img {
            width: 100%;
            height: 250px;
            object-fit: cover;
          }
        }
      }

      ._illustrate:nth-child(2) {
        position: relative;
        top: 100px;
      }
    }
  }

  .bigTitle_2 {
    text-align: center;
    font-size: 70px;
    line-height: 150px;
    color: #FE2858;
    background: #040404;
  }

  .mainContent2 {
    background: #040404;
    padding-top: 50px;
    margin: auto;
    display: flex;
    padding-bottom: 70px;

    ._mainContent2 {
      width: 1000px;
      margin: auto;
      display: flex;
      position: relative;

      .mainContent2Left {

        color: #F2F2F2;
        display: inline-block;
        padding: 10px 50px;
        font-size: 36px;
        z-index: 6;
        flex: 4;
        line-height: 80px;
      }

      .mainContent2LeftBg {
        background: #FE2858;
        width: 300px;
        height: 340px;
        position: absolute;
        z-index: 0;
      }

      .mainContent2Right {
        background: #FFFFFF;
        font-family: 'Montserrat', sans-serif;
        display: inline-block;
        color: #000000;
        padding: 0px 10px;
        flex: 2;
        position: relative;

        .mainContent2RightTitle {
          margin-top: 20px;
          text-align: center;
          font-size: 19px;
        }

        .bgImage {
          position: absolute;
          bottom: -40px;
          right: 20px;

          img {
            width: 70px;
          }
        }
      }
    }

  }
}
</style>
