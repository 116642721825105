<template>
  <kinesis-container>
    <div class="content">
      <div class="_content">
        <KinesisElement strength="25">
          <div class="bg"></div>
        </KinesisElement>
        <div class="bgImg">
          <KinesisElement strength="5">
            <div class="swiper">
              <img src="./../assets/image/Rectangle47@1x.png" alt="">
            </div>
          </KinesisElement>
          <div class="data">
            <KinesisElement strength="30">
              <div class="start">
                <div class="title">Start Now</div>
                <div class="dec">
                  <div>INSTANTLY RECEIVE</div>
                  <div>A $400 SIGNING BONUS</div>
                  <div>UPON CONTRACT COMPLETION</div>
                </div>
              </div>
            </KinesisElement>
            <div class="ready">
              <div class="title">Ready to talk?</div>
              <input type="text" placeholder="name" v-model="name">
              <input type="text" placeholder="email" v-model="email">
              <button @click="submit">CONTACT US</button>
            </div>
          </div>
        </div>
        <div class="tips">
          <span class="stong big_1">Overview</span>

          <br>
          <br>
          <span class="stong">Background</span>.The current global e-commerce shopping trend is shifting from
          traditional
          shelf e-commerce (Amazon,
          Temu, etc.) to content-driven e-commerce (TikTok, YouTube Shorts, etc.), where creative and engaging content
          makes shopping more enjoyable.
          <br>
          <br>
          Against this backdrop, TikTok opened its U.S. local shop market in the second half of 2023 and set a target
          GMV
          of
          $50 billion for the U.S. market in 2024. According to statistics, International sellers account for nearly 80%
          of
          sellers on major e-commerce platforms worldwide, and the proportion of products designed or manufactured
          outside
          of U.S. is even higher globally. Meanwhile, as the world's largest consumer market, American consumers are
          highly
          sought after by international merchants who want to sell their products in the U.S.
          <br>
          <br>
          However, due to various reasons, TikTok's local shop requirements for merchants are very stringent. According
          to
          TikTok's official policy, only companies registered by U.S. citizens are allowed to open local shops. This
          policy
          has been a significant obstacle for most international merchants who want to open local shops in the U.S.
          Despite
          having extensive experience in selling products overseas and supply chain advantages, international merchants
          face
          challenges such as finding U.S. citizens to open local shops, operating shops legally under U.S. laws and
          regulations, and managing corporate accounting and tax reporting.
          <br>
          <br>
          <span class="stong">What we do.Aivocado</span>, a leading tech and international trade services company based
          in
          the United States, we
          understand the importance of leveraging expertise in e-commerce operations to maximize our clients' profits.
          That's why we etablish the platform(ClickGenius). ClickGenius aims to help non-U.S. merchants with these needs
          by
          connecting them with willing U.S. citizens, creating mutual benefits for both parties.
          <br><br>
          <span class="stong big_2">Here are the key benefits of agency operating services through Aivocado:</span>
          <br><br>
          <span class="stong">Professional Support</span>. Our team comprises seasoned legal and tax professionals based
          in the US. We handle all
          legal
          and tax matters related to your TikTok Shop company, ensuring compliance with regulations and minimizing
          risks.
          This expert support guarantees a smooth and secure operating environment for your business.
          <br><br>
          <span class="stong">Financial Incentives</span>. By joining forces with us, you'll enjoy lucrative financial
          incentives. Upon signing up,
          you'll receive a generous signing bonus of $500. Additionally, you'll earn a monthly profit share of 0.6% of
          the
          sales revenue generated by your TikTok Shop. For example, if your shop achieves a monthly profit of $200,000,
          you'll earn $1,200 for that month. Furthermore, to provide stability and assurance, we guarantee a minimum
          monthly
          payout of $500, regardless of your shop's sales performance.
          <br><br>
          <span class="stong big_2">Requirements</span>
          <br><br>
          To ensure the smooth operation of our agency services and secure our clients' income, we review all
          application
          materials. Our services are only available to individuals who meet the following criteria:
          <br><br>
          <li>U.S. citizens</li>
          <li>Ability to comply with TikTok platform's review process</li>
          <li>Possess a valid bank statement</li>
          <li>No criminal record</li>
          <li>Willingness to provide tax returns to TikTok platform</li>
          <br>
          <br>
          <br>

          For more information, please Email through
          <br>
          <br>

          support@aivocado.online


        </div>
      </div>


    </div>
  </kinesis-container>
</template>

<script>
import {KinesisContainer, KinesisElement} from "vue-kinesis";
import axios from 'axios'

export default {
  name: "home-views-learn",
  data() {
    return {
      email: '',
      name: ''
    }
  },
  components: {

    KinesisContainer,
    KinesisElement
  },
  methods: {
    async submit() {
      let data = {
        "email": this.email,
        "name": this.name
      }
      await axios.post('https://server.aivocado.online/tt-tool-server/us-partner/submit-email', data);
      alert('Submission successful, staff will contact you via email shortly.')
    }
  }
}
</script>

<style scoped lang="less">
.content {
  ._content {
    width: 1000px;
    margin: auto;
    position: relative;

    .bg {
      background: #FE2858;
      width: 100%;
      height: 250px;
      position: absolute;
      top: 250px;
    }

    .bgImg {
      .swiper {
        position: relative;
        z-index: 9;

        img {
          width: 600px;
          margin: 60px 150px;

        }
      }

      .data {
        display: flex;
        height: 220px;
        margin-top: -150px;
        position: relative;
        z-index: 10;

        .start {
          background: #000000;
          padding: 20px;
          margin-left: 100px;

          .title {
            font-family: 'Roboto', sans-serif;
            color: #33F3ED;
            font-size: 18px;
            line-height: 50px;
          }

          .dec {
            color: #FE2858;
            font-family: 'Montserrat', sans-serif;
            font-size: 24px;
            line-height: 40px;
          }
        }

        .ready {
          background: #FFFFFF;
          padding: 20px;
          width: 250px;
          height: 300px;
          margin-left: 30px;
          margin-top: -50px;

          .title {
            font-family: 'Oswald', sans-serif;
            font-size: 30px;
            color: black;
          }

          input {
            border: none;
            outline: none;
            display: block;
            color: black;
            font-size: 14px;
            padding: 10px;
            border-bottom: 3px solid black;
            width: calc(100% - 20px);
            margin-top: 20px;
          }

          input::placeholder {
            color: black;
          }

          button {
            border: none;
            color: white;
            width: 100%;
            padding: 10px;
            cursor: pointer;
            background: black;
            color: white;
            margin-top: 50px;
            font-weight: 600;
          }
        }
      }
    }

    .tips {
      width: 800px;
      margin: 150px auto 50px auto;
      font-family: 'Roboto', sans-serif;
      color: #9E9E9E;
      font-size: 18px;

      .stong {
        font-weight: 600;
      }

      .big_1 {
        font-size: 20px;
      }

      .big_2 {
        font-size: 22px;
      }
    }
  }
}
</style>
