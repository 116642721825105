<template>
  <div class="header" :class="showHeader?'menuBg':''">
    <div class="menu">
      <img src="./../assets/icon/Menu@3x.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: "header-views",
  data() {
    return {
      showHeader: false
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      // 获取当前滚动位置（距离顶部的距离）
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // 输出或处理滚动位置
      if (scrollTop >= 100) {
        this.showHeader = true

      } else {
        this.showHeader = false

      }

      // 如果你想要监听某个特定元素的滚动位置，你可以替换为：
      // var element = document.getElementById('your-element-id');
      // var scrollTop = element.scrollTop;

      // ...然后你可以基于scrollTop的值来执行一些操作...
    });
  }

}
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  z-index: 999;
  transition: all 0.5s;

  .menu {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;


    img {
      width: 30px;
      height: auto;
    }
  }


}

.menuBg {
  background: white !important;
}
</style>
