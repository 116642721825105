<template>
  <kinesis-container>
    <div class="content">
      <div class="contentText">
        <KinesisElement strength="5">
          <div class="title">
            REQUIREMENT
          </div>
        </KinesisElement>
        <div class="oneContent">
          <div class="oneLeft">
            <KinesisElement strength="15">
              <div>Accessible For US Citizens</div>
              <div>Clean Record Assurance</div>
              <div>Zero Experience Needed</div>
            </KinesisElement>
          </div>
          <div class="oneRight">

            We meticulously review all prospective partners, initiating contact upon approval.
            <br>
            Instantly receive a <span style="color:#FE2858;font-weight: 600 ">$400</span> signing bonus upon contract
            completion

            <img src="./../assets/icon/Ellipse26@1x.png" alt="" class="bg">
          </div>
        </div>
      </div>
      <div class="contentText1">
        <div class="bg"></div>
        <KinesisElement strength="15">
          <div class="bgImg">
            <img src="./../assets/image/Rectangle37@0.75x.png" alt="">
          </div>
        </KinesisElement>
        <KinesisElement strength="35">
          <div class="titleBox">
            <div class="smallTitle">E-Commerce AGENCY</div>
            <div class="title">Digital Marketing</div>
            <div class="title">and Branding</div>
          </div>
        </KinesisElement>
        <KinesisElement strength="30">
          <div class="decBox">
            <div class="tips">we have experts who have been working for several
              years in E-commerce .
            </div>
            <div class="feature">
              <img src="./../assets/icon/Vector@2x.png" alt="">
              Building a Company from ground up.
            </div>
            <div class="feature">
              <img src="./../assets/icon/Vector@2x.png" alt="">
              Branding your E-Commerce Shop
            </div>
            <div class="feature">
              <img src="./../assets/icon/Vector@2x.png" alt="">
              Matching your manufacturer
            </div>
            <div class="feature">
              <img src="./../assets/icon/Vector@2x.png" alt="">
              Exiperienced team help you operate your shop
            </div>
            <div class="btn" @click="turn">
              LEARN MORE
            </div>
          </div>
        </KinesisElement>
      </div>
    </div>
  </kinesis-container>
</template>

<script>
import {KinesisContainer, KinesisElement} from "vue-kinesis";

export default {
  name: "requirement-views",
  components: {

    KinesisContainer,
    KinesisElement
  },
  methods: {
    turn() {
      this.$emit('turn')
    }
  }
}
</script>

<style scoped lang="less">
.content {
  background: white;
  height: 1570px;

  .contentText {
    width: 1000px;
    margin: auto;

    .title {
      color: #FE2858;
      font-size: 100px;
      padding-left: 50px;
    }

    .oneContent {
      padding-top: 40px;
      display: flex;

      .oneLeft {
        background: #000000;
        color: white;
        padding: 40px;
        font-size: 35px;
        flex: 2;
        line-height: 80px;
        box-shadow: 10px 10px 0px #FE2858;
        margin-right: 200px;
      }

      .oneRight {
        flex: 1;
        background: #F2F2F2;
        font-family: 'Montserrat', sans-serif;
        color: #000000;
        text-align: center;
        padding: 30px 40px;
        font-size: 18px;
        line-height: 30px;
        height: 220px;
        position: relative;

        .bg {
          position: absolute;
          width: 50px;

        }
      }
    }
  }

  .contentText1 {
    position: relative;
    margin: 80px auto 0px auto;
    width: 1000px;

    .bg {
      width: 300px;
      background: #FE2858;
      height: 920px;
      position: absolute;
      z-index: 0;
      left: 300px;
    }

    .bgImg {
      img {
        width: 800px;
        position: absolute;
        right: 0px;
        top: 150px;
        height: 450px;
        object-fit: cover;
      }
    }

    .titleBox {
      background: #000000;
      color: #FFFFFF;
      width: 390px;
      position: relative;
      z-index: 9;
      left: 100px;
      padding: 40px;
      top: 30px;

      .smallTitle {
        font-weight: 200;
        font-size: 25px;
      }

      .title {
        font-size: 45px;
      }
    }

    .decBox {
      background: #F2F2F2;
      position: relative;
      z-index: 9;
      width: 450px;
      padding: 40px 30px;
      top: 100px;

      .tips {
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-size: 20px;
      }

      .feature {
        margin-top: 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 17px;


        img {
          width: 17px;
          margin-right: 10px;
          margin-left: 10px;
        }
      }

      .btn {
        background: white;
        color: black;
        display: inline-block;
        padding: 10px 20px;
        margin-top: 20px;
        font-size: 30px;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
      }
    }
  }
}
</style>
