<template>
  <div class="content">
    <div class="contentText">
      <div class="_contentText">
        <div class="img">
          <img src="./../assets/image/Rectangle42@1x.png" alt="">
        </div>
        <div class="mainText">
          <div class="title">Contact Us</div>
          <div class="dec">to Start your E-Commerce Journey with 0 experience</div>
          <div class="email">
            <input type="text" placeholder="Enter a valid email address" v-model="email">
            <button @click="submit">SUBMIT</button>
          </div>
          <div class="contactUs">
            <div class="location">
              <div class="locationTilte">LOCATION</div>
              <div class="locationDec">
                605 Pavonia Ave
                <br>
                Jersey City, NJ
                <br>
                United Sates
              </div>
            </div>
            <div class="Follow">
              <div class="followTitle">FOLLOW US</div>
              <div class="icon">
                <img src="./../assets/icon/Vector@1xf.png" alt="">
                <img src="./../assets/icon/Vector@1xg.png" alt="">
                <img src="./../assets/icon/Vector@1xx.png" alt="">
                <img src="./../assets/icon/Vector@1xb.png" alt="">
              </div>
              <div class="company">
                <div><img src="./../assets/icon/Group@1x.png" alt="" style="margin: 0px 10px">AIvocado, Inc.</div>
                <div>support@aivocado.online</div>
                <div>2024 Privacy policy</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="bg"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "footer-views",
  data() {
    return {
      email: ''
    }
  },
  methods: {
    async submit() {
      let data = {
        "email": this.email,
        "name": ""
      }
      await axios.post('https://server.aivocado.online/tt-tool-server/us-partner/submit-email', data);
      alert('Submission successful, staff will contact you via email shortly.')
    }
  }
}
</script>

<style scoped lang="less">
.content {
  background: #040404;
  height: 600px;

  .contentText {
    width: 1000px;
    position: relative;
    margin: auto;

    .bg {
      background: #FE2858;
      position: absolute;
      width: 150px;
      height: 600px;
      left: 250px;
      top: 0px;
    }

    ._contentText {
      display: flex;

      .img {
        flex: 1;
        position: relative;
        height: 600px;

        img {
          height: 550px;
          position: absolute;
          bottom: 0px;
          z-index: 6;
        }
      }

      .mainText {
        flex: 2;

        .title {
          text-align: center;
          color: #65D2E9;
          margin-top: 100px;
          font-size: 50px;
        }

        .dec {
          text-align: center;
          color: white;
          font-size: 20px;
        }

        .email {
          display: inline-block;
          margin: auto;
          position: relative;
          z-index: 9;
          width: 550px;
          left: 100px;
          top: 70px;

          input {
            width: 300px;
            outline: none;
            padding: 10px 30px;
            font-size: 23px;
            border: none;
            background: none;
            border-bottom: 3px white solid;
            color: white;

          }

          input::placeholder {
            color: white;
            opacity: 1; /* Firefox 需要 opacity: 1 来覆盖默认颜色 */
          }

          button {
            background: #FE2858;
            color: white;
            padding: 10px 30px;
            font-size: 23px;
            outline: none;
            border: none;
            margin-left: 30px;
            cursor: pointer;
          }
        }

        .contactUs {
          margin-top: 100px;
          margin-left: 100px;
          display: flex;
          color: white;

          .Follow {
            margin-left: 50px;

            .followTitle {
              text-align: right;
            }

            .icon {
              margin-left: 50px;

              img {
                margin-right: 30px;
              }
            }

            .company {
              font-family: 'Roboto', sans-serif;
              margin-top: 30px;
            }
          }

          .locationDec {
            margin-top: 20px;
            font-family: 'Roboto', sans-serif;

          }

          .locationTilte {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
