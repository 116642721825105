<template>
  <div>
    <headerViews></headerViews>
    <homeViews></homeViews>
    <brief-views @turn="turn"></brief-views>
    <caseViews></caseViews>
    <legalViews></legalViews>
    <requirementViews @turn="turn"></requirementViews>
    <footerViews></footerViews>
  </div>
</template>

<script>
import headerViews from "@/components/header-views";
import homeViews from "@/components/home-views";
import briefViews from "@/components/brief-views";
import caseViews from "@/components/case-views";
import legalViews from "@/components/legal-views";
import requirementViews from "@/components/requirement-views";
import footerViews from "@/components/footer-views";


export default {
  name: "all-home-views",
  components: {
    headerViews,
    homeViews,
    briefViews,
    caseViews,
    legalViews,
    requirementViews,
    footerViews
  },
  methods: {
    turn() {
      this.$router.push('/learn')
    }
  }
}
</script>

<style scoped>

</style>
