<template>
  <router-view class="zoom" :style="zoomStyle"></router-view>
</template>

<script>


export default {
  name: 'App',

  data() {
    return {
      zoom: 1,
    }
  },
  computed: {
    zoomStyle() {
      // 根据 zoom 的值来返回样式对象
      if (this.zoom < 1) {
        // 如果 zoom 大于 1，则添加 zoom 样式

        return {
          zoom: `${this.zoom}`, // 注意：这里可能需要根据实际情况转换为百分比或其他合适的格式
        };
      } else {
        // 如果 zoom 不大于 1，则不添加 zoom 样式（或者添加 zoom: 1，但通常这不是必要的）
        return {};
      }
    }
  },
  mounted() {

    this.resizeWidth()

  },
  methods: {
    resizeWidth() {
      if (window.innerWidth < 1180) {
        this.zoom = window.innerWidth / 1180
        //console.log("缩放zoom", this.zoom, window.innerWidth)
      }
      window.addEventListener('resize', () => {
        // 在这里编写处理窗口大小变化的代码
        if (window.innerWidth < 1180) {
          //console.log("缩放zoom")
          this.zoom = window.innerWidth / 1180
        }
      });
    },
    turn() {
      this.page = 2
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: 'Oswald', sans-serif;

}

html {
  scroll-behavior: smooth; /* 平滑滚动 */
}

body {
  margin: 0px;
  padding: 0px;
  background: #F2F2F2;
}


@font-face {
  font-family: 'Oswald';
  src: url("./assets/fonts/Oswald.woff2") format('woff2'),
  url("./assets/fonts/Oswald_mianfeiziti.com.ttf") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("./assets/fonts/Montserrat.woff2") format('woff2'),
  url("./assets/fonts/Montserrat_mianfeiziti.com.ttf") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto.woff2") format('woff2'),
  url("./assets/fonts/Roboto_mianfeiziti.com.ttf") format('woff');
  font-weight: normal;
  font-style: normal;
}

.content {
  width: 100%;
}

/*@media screen and (max-width: 1180px) {*/
/*  .zoom {*/
/*    zoom: calc(100% / 1180);*/
/*  }*/
/*}*/
a {
  color: inherit;
  text-decoration: none;
}
</style>
