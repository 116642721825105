<template>
  <kinesis-container>
    <div class="content">
      <KinesisElement strength="5">
        <div class="title">From Zero to Success</div>
      </KinesisElement>
      <div class="mainContent">
        <div class="left">
          <KinesisElement strength="15">
            <div class="leftText">
              We help you start
              your new company
              & E-Commerce shops
              with ease
            </div>
            <br style="clear: both">
          </KinesisElement>
        </div>
        <div class="right">
          <KinesisElement strength="35">
            <div class="dec">
              Experienced in E-commerce Team help you to maximize your TikTok Shop profit
              <br>
              <br>
              Help you match 1000+ merchants
            </div>
            <div class="btn" @click="check">
              Learn More
            </div>
          </KinesisElement>
          <img src="./../assets/image/header6@2x.png" alt="" class="bg">

        </div>
      </div>
    </div>
  </kinesis-container>
</template>

<script>
import {KinesisContainer, KinesisElement} from "vue-kinesis";

export default {
  name: "brief-views",
  components: {

    KinesisContainer,
    KinesisElement
  },
  methods: {
    check() {
      this.$emit('turn')
    }
  }
}
</script>

<style scoped lang="less">
.content {


  .title {
    font-size: 80px;
    line-height: 180px;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    color: #FE2858;
  }

  .mainContent {
    display: flex;
    width: 1000px;
    margin: auto;
    padding-bottom: 60px;

    .left {
      flex: 2;
      font-size: 40px;
      line-height: 70px;
      font-family: 'Oswald', sans-serif;
      color: #000000;
      text-align: right;

      .leftText {
        width: 320px;
        float: right;
        margin-right: 20px;
      }
    }

    .right {
      flex: 1;
      background: #FFFFFF;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      color: #000000;
      padding: 30px;
      font-size: 23px;
      position: relative;

      .btn {
        background: #E6446E;
        border-radius: 20px;
        display: inline-block;
        padding: 10px 3px;
        color: white;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
      }

      .bg {
        position: absolute;
        width: 80px;
        height: auto;
        bottom: 20px;
        right: 20px;
      }
    }
  }
}

</style>
