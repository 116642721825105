<template>
  <div class="content">
    <div class="logo">

      <div class="_logo" @click="turnHome">
        <img src="./../assets/image/header6@2x.png" alt="">
      </div>
      <div class="name" @click="turnHome">
        <div class="_name1">
          <img src="./../assets/image/ClickGenius@1x(1).png" alt="">
        </div>
        <div class="_name2">
          <img src="./../assets/image/ClickGenius@1x.png" alt="">
        </div>
        <div class="_name3">
          <img src="./../assets/image/ClickGenius@1x(2).png" alt="">
        </div>
      </div>
    </div>
    <div class="menu">
      <img src="./../assets/icon/Menu@3x.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "header-views-learn",
  methods: {
    turnHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="less">
.content {
  background: white;
  height: 70px;
  position: relative;

  .logo {
    display: flex;

    ._logo {
      margin-left: 30px;
      cursor: pointer;

      img {
        width: 60px;
        margin-top: 5px;
      }
    }
  }


  .name {
    position: relative;
    height: 80px;
    cursor: pointer;


    img {
      margin-top: 15px;
      margin-left: 15px;
      height: 40px;

    }

    ._name1 {
      position: absolute;
      left: 3px;
    }

    ._name2 {
      position: absolute;
      z-index: 3;
    }

    ._name3 {
      position: absolute;
      left: -3px;
      top: -3px;
    }
  }

  .menu {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;


    img {
      width: 30px;
      height: auto;
    }
  }
}
</style>
